export const chunkArray = <T>(arr: T[], chunkSize: number): T[][] => {
  const chunks = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize));
  }
  return chunks;
};

export const changeArrayElementOrder = <T>(arr: T[], targetIndex: number, oldIndex: number): T[] => {
  const result: T[] = [...arr];
  const [element] = result.splice(oldIndex, 1);
  result.splice(targetIndex, 0, element);
  return result;
};

export const isLastIdxInArr = (arr: any, applicantIdx: number): boolean => applicantIdx === arr.length - 1;

export const includesCaseInsensitive = (arr: string[], value: string): boolean =>
  arr.some((arrValue) => {
    const formattedElm = (arrValue || '').trim().toLowerCase();
    const formattedValue = (value || '').trim().toLowerCase();
    const included = formattedElm.indexOf(formattedValue) !== -1;
    return included;
  });

export const splitArrayToRestAndLast = <T>(arr: T[]): [T[], T | null] => {
  const [theLast, ...rest] = arr.reverse();
  return [rest.reverse(), theLast];
};
