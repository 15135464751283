interface LocalStorageServiceConfig {
  /**
   * Prefix before keys of local storage
   * items that the service works with.
   */
  prefix: string;
}

export const createLocalStorageService = ({ prefix }: LocalStorageServiceConfig) => {
  /**
   * Extracts configuration value from local storage.
   * @param key Configuration key
   * @returns Parsed configuration value
   */
  const extractFromLocalStorage = (key: string): any => {
    const strValue = localStorage.getItem(`${prefix}:${key}`);

    if (strValue) {
      return JSON.parse(strValue);
    }

    return null;
  };

  /**
   * Writes configuration value to local storage in JSON format.
   * @param key Configuration key
   * @param value New value of configuration value
   */
  const writeToLocalStorage = (key: string, value: any) => {
    localStorage.setItem(`${prefix}:${key}`, JSON.stringify(value));
  };

  /**
   * Decorates a setter function with synchronization of local storage
   * @param func Setter function to decorate
   * @param key Configuration
   * @returns Function decorated with synchronization of local storage
   */
  const decorateSetterWithSync = <T, F extends (value: T) => void>(func: F, key: string) => {
    return (value: T): void => {
      writeToLocalStorage(key, value);
      func(value);
    };
  };

  return {
    extractFromLocalStorage,
    writeToLocalStorage,
    decorateSetterWithSync,
  };
};
