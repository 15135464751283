import { DateTime } from 'luxon';

export const formatDate = (date: DateTime): string => date.toFormat('MMM dd, yyyy');

export const formatDateFromISOString = (isoDateString: string): string => {
  const date = DateTime.fromISO(isoDateString);
  return formatDate(date);
};

export const formatDateFromTimestamp = (timestamp: number): string => {
  const date = DateTime.fromMillis(timestamp);
  return formatDate(date);
};

export const generateHoursWith30MinIntervals = (): string[] => {
  const hours = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const ampm = hour < 12 ? 'AM' : 'PM';
      const formattedHour = `${((hour + 11) % 12) + 1}:${String(minute).padStart(2, '0')} ${ampm}`;
      hours.push(formattedHour);
    }
  }
  return hours;
};
