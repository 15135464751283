/* =================== SET MUI PRO PACKAGE LICENSE =================== */
import { LicenseInfo } from '@mui/x-license-pro';
import { EnvType, envType, muiLicense } from 'config';
LicenseInfo.setLicenseKey(muiLicense);
/* ==================================================================== */

import React from 'react';
import type { AppProps } from 'next/app';
import { NextAdapter } from 'next-query-params';
import { QueryParamProvider } from 'use-query-params';
import dynamic from 'next/dynamic';
import { SnackbarProvider } from 'notistack';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { ThemeProvider, CssBaseline, createTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools as QueryDevtools } from 'react-query/devtools';
import type {} from '@mui/x-date-pickers/themeAugmentation';

import '@material-design-icons/font/filled.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { createEmotionCache } from 'utils';
import theme from '../styles/theme';
import '../styles/globals.css';

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const clientSideEmotionCache = createEmotionCache();
const lightTheme = createTheme(theme);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 60 * 60000, // 60 minutes
    },
  },
});

const ReactQueryDevtools = dynamic<React.ComponentProps<typeof QueryDevtools>>(
  async () => {
    const { ReactQueryDevtools } = await import('react-query/dist/react-query-devtools.development');
    return ReactQueryDevtools;
  },
  { ssr: false }
);

const MyApp: React.FunctionComponent<MyAppProps> = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const isProduction = envType === EnvType.PROD;

  return (
    <CacheProvider value={emotionCache}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={lightTheme}>
          <CssBaseline />
          <QueryClientProvider client={queryClient}>
            <QueryParamProvider adapter={NextAdapter}>
              <SnackbarProvider maxSnack={3}>
                <Component {...pageProps} />
              </SnackbarProvider>
            </QueryParamProvider>
            {!isProduction ? <ReactQueryDevtools initialIsOpen={false} /> : null}
          </QueryClientProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </CacheProvider>
  );
};

export default MyApp;
