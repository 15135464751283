/**
 * formats <code>applicant</code> argument to two decimals and returns it as string;
 * if the <code>applicant</code> argument is originally of the string type, it is parsed to float and
 * then formatted.
 */
export const formatToTwoDecimals = (applicant: number | string): string => {
  let result = applicant;
  if (typeof applicant === 'string') {
    const parsedFloat = parseFloat(applicant);

    if (isNaN(parsedFloat)) {
      return applicant;
    }
    result = parsedFloat;
  }
  return `${Math.floor((result as number) * 100) / 100}`;
};
