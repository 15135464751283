import { makeValidator } from './utils';

interface MinMaxValidationConfig {
  value: number;
  trim?: boolean;
  strict?: boolean;
}

export const min = makeValidator((value: string, config: MinMaxValidationConfig) => {
  let valueToValidate = value;

  if (config.trim) {
    valueToValidate = valueToValidate.trim();
  }

  if (!config.strict) {
    return valueToValidate.length >= config.value;
  }

  return valueToValidate.length > config.value;
}, {});

export const max = makeValidator((value: string, config: MinMaxValidationConfig) => {
  let valueToValidate = value;

  if (config.trim) {
    valueToValidate = valueToValidate.trim();
  }

  if (!config.strict) {
    return valueToValidate.length <= config.value;
  }

  return valueToValidate.length < config.value;
}, {});

export const year = makeValidator((value: string, _config: {}) => /^(19|20)\d{2}$/.test(value), {});

export const number = makeValidator((value: string, _config: {}) => !isNaN(Number(value)), {});

interface NumberLimitConfig {
  min?: number;
  max?: number;
  strict?: boolean;
}

export const numberLimit = makeValidator(
  (value: string, { min = -Infinity, max = Infinity, strict = false }: NumberLimitConfig) => {
    const numberValue = Number(value);

    if (!strict) {
      return numberValue >= min && numberValue <= max;
    }

    return numberValue > min && numberValue < max;
  },
  {}
);
