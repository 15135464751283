import { DashboardColumnConfig, SelectItem } from 'types';
import { makeSelectItems } from 'utils';

const dashboardColumnsConfigs: DashboardColumnConfig[] = [
  { field: 'name', visible: true, artificial: true, nativeFields: ['firstName', 'lastName'] },
  { field: 'isHot', visible: true },
  { field: 'email', visible: false },
  { field: 'created_date', visible: true },
  { field: 'applicationStage', visible: false },
  { field: 'availability_type', visible: true },
  {
    field: 'avEngHours',
    visible: true,
    artificial: true,
    nativeFields: ['available_hours_per_week', 'total_hours_engaged'],
  },
  {
    field: 'rate_per_hour',
    visible: true,
    artificial: true,
    nativeFields: ['min_rate_per_hour', 'max_rate_per_hour', 'rateInfo'],
  },
  {
    field: 'client_rate_per_hour',
    visible: false,
  },
  { field: 'oldestReminderTimestamp', visible: false, artificial: true },
  { field: 'oldestReminderComment', visible: false, artificial: true },
  { field: 'role', visible: false },
  { field: 'team', visible: false, artificial: true, nativeFields: ['teamId'] },
  { field: 'payoneer_name', visible: false },
  { field: 'w8ben_link', visible: false },
  { field: 'specializations', visible: true },
  { field: 'level', visible: false },
  { field: 'yearsOfExperience', visible: false },
  { field: 'link_to_cv', visible: false },
  { field: 'linkedin', visible: false },
  { field: 'status_in_system', visible: false },
  { field: 'status_change_date', visible: false },
  { field: 'last_change_date', visible: false },
  { field: 'last_note_date', visible: false },
  { field: 'last_note', visible: false },
  { field: 'mainSkills', visible: true },
  { field: 'otherSkills', visible: true },
  { field: 'tagNames', visible: false },
  { field: 'country', visible: true },
  { field: 'languages', visible: false },
  { field: 'daysInTheCommunity', visible: false },
  { field: 'projectsOfferedCount', visible: false },
  { field: 'activatedSubscriptionsCount', visible: false },
  { field: 'weakCandidateDeclinesCount', visible: false },
  { field: 'nonOrganicChurnsCount', visible: false },
  { field: 'city', visible: false },
  { field: 'telegram', visible: false },
  { field: 'phone', visible: false },
];

export const dashboardColumnsConfig = dashboardColumnsConfigs.reduce<Record<string, DashboardColumnConfig>>(
  (acc, colConfig) => ({ ...acc, [colConfig.field]: colConfig }),
  {}
);

export const COLUMNS_INIT_ORDER = dashboardColumnsConfigs.map(({ field }) => field);

export enum ContactStatuses {
  NEW = 'New',
  OUTREACHED = 'Outreached',
  LONG_LIST = 'Long list',
  IN_PROCESS = 'In Process',
  SCREENING_CALL = 'Screening call',
  HARD_SKILLS_INTERVIEW = 'Hard skills interview',
  FINAL_BRIEFING = 'Final briefing',
  AVAILABLE = 'Available',
  ENGAGED = 'Engaged',
  PARTNERS_LISTED = 'Partners listed',
  FULLY_ENGAGED = 'Fully engaged with client',
  TEMPORARY_UNAVAILABLE = 'Temporary unavailable',
  FOR_FUTURE = 'For future',
  DECLINED = 'Declined',
  REFUSED = 'Refused',
  QUIT = 'Quit',
  FIRED = 'Fired',
  DISABLED = 'Disabled',
  DELETE = 'Delete',
}

export const CONTACT_STATUSES: ContactStatuses[] = Object.values(ContactStatuses);

export enum ContactRoles {
  ALL = 'All',
  FREELANCER = 'Freelancer',
  TEAM = 'Team',
}

export const CONTACT_ROLES: ContactRoles[] = Object.values(ContactRoles);

export const CONTACT_ROLES_ITEMS: Array<SelectItem<string>> = makeSelectItems(CONTACT_ROLES);

export enum ContactAvailabilityTypes {
  PART_TIME = 'Part-time',
  FULL_TIME = 'Full-time',
  PART_THEN_FULL_TIME = 'PT -> FT',
  PART_AND_FULL_TIME = 'PT & FT',
  UNKNOWN = 'unknown',
  UNAVAILABLE = 'Unavailable',
}

export const CONTACT_AVAILABILITY_TYPES: ContactAvailabilityTypes[] = Object.values(ContactAvailabilityTypes);

export enum ContactAvailabilityHours {
  LESS_TWENTY = '<20h',
  TWENTY = '20h',
  TWENTY_FIVE = '25h',
  THIRTY = '30h',
  THIRTY_FIVE = '35h',
  FORTY = '40h',
  UNKNOWN = 'Unknown',
}

export const CONTACT_AVAILABILITY_HOURS: ContactAvailabilityHours[] = Object.values(ContactAvailabilityHours);

export enum HubspotProjectStatus {
  ALL = 'All',
  PUBLISHED = 'Published',
  UNPUBLISHED = 'Unpublished',
}

export const HUBSPOT_PROJECT_STATUSES: HubspotProjectStatus[] = Object.values(HubspotProjectStatus);

export const HUBSPOT_PROJECT_STATUSES_ITEMS: Array<SelectItem<string>> = makeSelectItems(HUBSPOT_PROJECT_STATUSES);
