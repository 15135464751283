import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    lightBackground: { primary: string };
    whiteBackground: { primary: string };
    greyBackground: { primary: string };
  }

  interface PaletteOptions {
    lightBackground: Palette['lightBackground'];
    whiteBackground: Palette['whiteBackground'];
    greyBackground: Palette['greyBackground'];
  }
}

const lightThemeOptions = createTheme({
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiButtonBase-root': {
            textTransform: 'none',
            whiteSpace: 'nowrap',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-root': {
            padding: '6px 12px',
            fontSize: '13px',
          },
          '&.MuiButton-root.MuiButton-sizeSmall': {
            padding: '4px 10px',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&.MuiInput-underline': {
            '&::before': {
              borderColor: 'rgba(0, 0, 0, 0.06)',
            },
          },
        },
      },
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#832CE0',
      light: '#C195EF',
    },
    secondary: { main: '#F50057' },
    success: { main: '#4CAF50', contrastText: '#FFFFFF' },
    warning: { main: '#ED6C03', contrastText: '#FFFFFF' },
    error: { main: '#F44336' },
    lightBackground: {
      primary: 'rgba(197, 143, 255, 0.16)',
    },
    whiteBackground: {
      primary: '#FFFFFF',
    },
    greyBackground: {
      primary: '#E3E3E3',
    },
    action: {
      disabled: 'rgba(0, 0, 0, 0.26)',
    },
  },
});

export default lightThemeOptions;
