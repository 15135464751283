export enum EnvType {
  LOCAL = 'local',
  DEV = 'dev',
  PROD = 'prod',
}

const envType: EnvType = ((): EnvType => {
  const type = process.env.NEXT_PUBLIC_ENV_TYPE as unknown as EnvType;
  if (!type) {
    return EnvType.LOCAL;
  }
  if (!Object.values(EnvType).includes(type)) {
    throw new Error(
      'The NEXT_PUBLIC_ENV_TYPE variable in .env should be one of the following values: "local", "dev", "prod".'
    );
  }
  return type;
})();

const [baseApiUrl, baseAuthUrl, baseUrl, basePublicUrl] = (() => {
  const apiUrl = process.env.NEXT_PUBLIC_API_URL;
  if (!apiUrl) {
    throw new Error('The NEXT_PUBLIC_API_URL variable in .env could not be discovered.');
  }
  const baseUrl = apiUrl[apiUrl.length - 1] === '/' ? apiUrl : `${apiUrl}/`;
  const baseApiUrl = `${baseUrl}api/`;
  const baseAuthUrl = `${baseUrl}auth/`;
  const basePublicUrl = `${baseUrl}public/`;
  return [baseApiUrl, baseAuthUrl, baseUrl, basePublicUrl];
})();

const muiLicense = ((): string => {
  const license = process.env.NEXT_PUBLIC_MUI_LICENSE;
  if (!license) {
    throw new Error('The NEXT_PUBLIC_MUI_LICENSE variable in .env could not be discovered.');
  }
  return license;
})();

const localEnvAuthKey = ((): string => {
  const key = process.env.NEXT_PUBLIC_LOCAL_AUTH_KEY || '';
  if (envType === EnvType.LOCAL && key === '') {
    throw new Error('For the Local environment, the NEXT_PUBLIC_LOCAL_AUTH_KEY variable must be set in .env file.');
  }
  return key;
})();

const oldCrmUrl = ((): string => {
  const url = process.env.NEXT_PUBLIC_CRM_1_URL;
  if (!url && (envType === EnvType.PROD || envType === EnvType.DEV)) {
    throw new Error('NEXT_PUBLIC_CRM_1_URL variable in the .env file could not be discovered');
  }
  return url!;
})();

const appLemonUrl = ((): string => {
  const url = process.env.NEXT_PUBLIC_APP_LEMON_URL;
  if (!url) {
    throw new Error('NEXT_PUBLIC_APP_LEMON_URL variable in the .env file could not be discovered');
  }
  return url!;
})();

const partnerUrl = ((): string => {
  const url = process.env.NEXT_PUBLIC_PARTNER_URL;
  if (!url) {
    throw new Error('NEXT_PUBLIC_PARTNER_URL variable in the .env file could not be discovered');
  }
  return url!;
})();

const hubspotUrl = ((): string => {
  const url = process.env.NEXT_PUBLIC_HUBSPOT_URL;
  if (!url) {
    throw new Error('NEXT_PUBLIC_HUBSPOT_URL variable in the .env file could not be discovered');
  }
  return url!;
})();

const meLemonUrl = ((): string => {
  const url = process.env.NEXT_PUBLIC_ME_LEMON_URL;
  if (!url) {
    throw new Error('NEXT_PUBLIC_ME_LEMON_URL variable in the .env file could not be discovered');
  }
  return url!;
})();

const crmUrl = ((): string => {
  const url = process.env.NEXT_PUBLIC_CRM_URL;
  if (!url) {
    throw new Error('NEXT_PUBLIC_CRM_URL variable in the .env file could not be discovered');
  }
  return url!;
})();

const defaultScreeningCallLink = ((): string => {
  const url = process.env.NEXT_PUBLIC_DEFAULT_SCREENING_CALL_LINK;
  if (!url) {
    throw new Error('NEXT_PUBLIC_DEFAULT_SCREENING_CALL_LINK variable in the .env file could not be discovered');
  }
  return url!;
})();

const magicPortalUrl = ((): string => {
  const url = process.env.NEXT_PUBLIC_MAGIC_PORTAL_URL;
  if (!url) {
    throw new Error('NEXT_PUBLIC_MAGIC_PORTAL_URL variable in the .env file could not be discovered');
  }
  return url!;
})();

export {
  baseApiUrl,
  baseAuthUrl,
  basePublicUrl,
  muiLicense,
  baseUrl,
  envType,
  localEnvAuthKey,
  oldCrmUrl,
  appLemonUrl,
  partnerUrl,
  hubspotUrl,
  meLemonUrl,
  crmUrl,
  defaultScreeningCallLink,
  magicPortalUrl,
};
