import { magicPortalUrl } from 'config';

interface CreatePublicCvLinkParams {
  candidateId: string;
  availability: string;
  rate: string;
}

interface CreateMatchedCvLinkParams {
  candidateId: string;
  projectId: string;
}

export const createPublicCvLink = ({ candidateId, availability, rate }: CreatePublicCvLinkParams) => {
  return `${magicPortalUrl}/share/candidate?id=${candidateId}&availability=${availability}&rate=${rate}`;
};

export const createMatchedCvLink = ({ candidateId, projectId }: CreateMatchedCvLinkParams) => {
  return `${magicPortalUrl}/requests/${projectId}/candidates/${candidateId}`;
};
