import { fromMarkdown } from 'mdast-util-from-markdown';
import { toHast } from 'mdast-util-to-hast';
import { raw } from 'hast-util-raw';
import { sanitize } from 'hast-util-sanitize';
import { toHtml } from 'hast-util-to-html';

export const mdToHtml = (md: string): string => {
  const mdast = fromMarkdown(md);
  const hast = raw(toHast(mdast, { allowDangerousHtml: true })!);
  const safeHast = sanitize(hast);
  const html = toHtml(safeHast);
  return html;
};
