import { type ControllerFieldState } from 'react-hook-form';
import createCache from '@emotion/cache';
import { GridEnrichedColDef } from '@mui/x-data-grid-pro';
import { SelectItem, DashboardColumnConfig, ColumnsStoredSettings } from 'types';

export const createEmotionCache = () => createCache({ key: 'css' });

export const assignFirstIfDefined = <T = boolean>(first: T | undefined, second: T): T =>
  first !== undefined ? first : second;

export const orderColumns = (columnDefs: GridEnrichedColDef[], columnsOrder: string[]): GridEnrichedColDef[] =>
  columnsOrder.reduce((acc: GridEnrichedColDef[], colField) => {
    const orderedColumnDef = columnDefs.find((colDef) => colDef.field === colField);
    if (orderedColumnDef) {
      return [...acc, orderedColumnDef];
    }
    return acc;
  }, []);

export const fileToDataURI = (file: File): Promise<string> =>
  new Promise((resolve) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      resolve(reader.result as string);
    });

    reader.readAsDataURL(file);
  });

export const getError = (fieldState: ControllerFieldState) =>
  fieldState.error &&
  (fieldState.error?.message && fieldState.error.message !== 'Invalid input' ? fieldState.error.message : true);

export const makeSelectItems = <T extends string>(strList: Array<T>): Array<SelectItem<T>> =>
  strList.map((str) => ({ value: str, name: str }));

export const getColumnVisibility = (
  field: string,
  initConfig: Record<string, DashboardColumnConfig>,
  lsConfig: ColumnsStoredSettings
): boolean => {
  if (lsConfig[field] && lsConfig[field].isVisible !== undefined) {
    // TODO: need to fix the bug of storing visibility of a column to local storage, currently
    // is isVisible is set to false there, it means column IS visible :)
    return !lsConfig[field].isVisible;
  }
  if (!initConfig[field]) {
    return false;
  }
  return initConfig[field].visible;
};

const convertHtmlToPlain = (html: string) => {
  const root = document.createElement('div');
  root.innerHTML = html;
  return root.textContent || root.innerText || '';
};

interface CopyTextOptions {
  html?: boolean;
}

export const copyText = (value: string, { html = false }: CopyTextOptions = {}) => {
  const clipboardItem = new ClipboardItem({
    'text/plain': new Blob([html ? convertHtmlToPlain(value) : value], { type: 'text/plain' }),
    ...(html && { 'text/html': new Blob([value], { type: 'text/html' }) }),
  });

  navigator.clipboard.write([clipboardItem]);
};

export const downloadFile = (data: Blob, fileName: string) => {
  const href = URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};
