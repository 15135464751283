/**
 * @param validation Validation function
 * @param msg Message map
 */
export const makeValidator = function <
  TValidation extends { (value: TValue, config: TConfig): boolean },
  TMsgGetter extends (options: any) => string,
  TMsgRecord extends Record<string, TMsgGetter>,
  TValue = Parameters<TValidation>[0],
  TConfig = Parameters<TValidation>[1]
>(validation: TValidation, msg: TMsgRecord) {
  const _validation = (config: TConfig) => (value: TValue) => validation(value, config);
  _validation.msg = msg;
  return _validation;
};
