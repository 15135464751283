export enum Routes {
  dashboard = '/dashboard',
  hotStacks = '/hot-stacks',
  skillDictionary = '/skill-dictionary',
  profile = '/profile',
  login = '/login',
  notFoundPage = '/404',
  projects = '/projects-dashboard',
  project = '/project',
  tagsDictionary = 'tags-dictionary',
}
