/**
 * redirects browsers to the <code>url</code> within the same page/tab
 */
export const redirectBrowserTo = (url: string): void => {
  window.location.replace(url);
};

export const getSearchParameterValue = (paramName: string): string | null => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(paramName);
};
